import React, { useState } from 'react';
import Service from './service';
import axios from 'axios';
import './LandingPage.css';


const LandingPage = () => {
  const [service] = useState(Service());
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    message: ''
  });
  
  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent page reload
  
    // Send data to the backend
    try {
      const response = await fetch(service.baseURL+'/users/comingSoon', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
  
      if (response.ok) {
        // Handle successful submission (e.g., show a success message)
        alert('Message sent successfully');
        console.log('Message sent successfully');
      } else {
        // Handle errors (e.g., show an error message)
        alert('Failed to send message');
        console.error('Failed to send message');
      }
    } catch (error) {
      alert('Error:', error)
      console.error('Error:', error);
    }
  };
//   const [formData, setFormData] = useState({
//     name: '',
//     email: '',
//     suggestion: ''
//   });
//   const [service] = useState(Service());

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log('Form Data Submitted: ', formData);

  //   // You can send the formData to your backend here using fetch or axios

  //   // Clear form fields
  //   setFormData({
  //     name: '',
  //     email: '',
  //     suggestion: ''
  //   });
  //   console.log(formData)
  //   axios.post(service.baseURL+'/users/comingSoon',formData,{
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   }).then((res)=>{
  //     console.log(res)
  //   }).catch((err)=>{
  //     console.log(err)
  //   })
  //   alert('Thank you for your suggestion!');
  // };

  return (
    <section className="hero">
      <div className="hero__main">
        <h1 className='main__heading'>COMING SOON</h1>
        <p className='main__content main__content--semiBold'>Associated and backed by</p>
        <img src={"/assets/sineLogo.png"} alt='SINE logo' height={100} />
        {/* <Link to='/'> */}
          <p className='main__content main__content--bold'>NeuralThread</p>
        {/* </Link> */}
        <p className='main__content'>Unleashing the Future of Textile Design with</p>
        <p className='main__content main__content--semiBold'>AI Pattern Generation</p>

        <form className="main__form" onSubmit={handleSubmit}>
          <p className="form__header">SEND MESSAGE</p>
          <input
            className="form__input form__input--left"
            placeholder="Your Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
          <input
            className="form__input form__input--right"
            placeholder="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleInputChange}
          />
          <input
            className="form__input"
            placeholder="Email id"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <textarea
            className="form__input form__msg"
            placeholder="Message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
          />
          <button className="form__submit" type="submit">SEND</button>
        </form>
      </div>
      <div className="hero__imgs">
        <div className="imgs">
          <img className='img img--left' src={"/assets/faceSplit2.jpeg"} alt='' height={140} width={200} />
          <img className='img img--right' src={"/assets/ufo.jpg"} alt='' height={140} width={200} style={{ objectPosition: 'top' }} />
          <img className='img img--left' src={"/assets/city.jpg"} alt='' height={140} width={200} style={{ objectPosition: '0 -80px' }} />
          <img className='img img--right' src={"/assets/floralbg.jpg"} alt='' height={140} width={200} />
          <img className='img img--center' src={"/assets/record.jpg"} alt='' height={140} width={200} />
        </div>
      </div>
    </section>
  );
};

export {LandingPage};
