import React from 'react';
import { Banner1 } from "./components/Banner1";
import { Banner2 } from "./components/Banner2";
import { LandingPage } from "./LandingPage"
import './App.css';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';

/*const images = [
    { image: "/assets/img1.jpeg", name: "Item 1", price: 499 },
    { image: "/assets/img2.png", name: "Item 2", price: 499 },
    { image: "/assets/img3.jpeg", name: "Item 3", price: 499 },
    { image: "/assets/img4.jpeg", name: "Item 4", price: 499 },
    { image: "/assets/img5.jpeg", name: "Item 5", price: 499 },
    { image: "/assets/img6.jpeg", name: "Item 6", price: 499 },
    { image: "/assets/img7.jpeg", name: "Item 7", price: 499 },
    { image: "/assets/img8.jpeg", name: "Item 8", price: 499 },
    { image: "/assets/img9.jpeg", name: "Item 9", price: 499 },
    { image: "/assets/img10.jpeg", name: "Item 10", price: 499 },
    { image: "/assets/img11.jpeg", name: "Item 11", price: 499 },
    { image: "/assets/img12.jpeg", name: "Item 12", price: 499 },
    { image: "/assets/img13.jpeg", name: "Item 13", price: 499 }
].map((image) => ({
    id: crypto.randomUUID(),
    ...image
}));

function Header() {
    return (
        <header className="header">
            <div className="header-left">
                <div className="gradient-circle"></div>
                <h1>Neural Thread</h1>
            </div>
            <div className="header-center">
                <img src="assets/search_icon.png" alt="Search Icon" className="search-icon" />
            </div>
            <div className="header-right">
                <h1>Abhishek Dutta</h1>
                <img src="assets/profile_pic.png" alt="Profile" className="profile-pic" />
            </div>
        </header>
    );
}

function Footer() {
    return (
        <footer className="footer">
            <p>Footer</p>
        </footer>
    );
}

function ImageGrid({ images }) {
    const columns = [[], [], [], [], []];
    images.forEach((img, index) => {
        const columnIndex = index % 5;
        columns[columnIndex].push(img);
    });

    return (
        <div className="container">
            {columns.map((columnImages, columnIndex) => (
                <div key={columnIndex} className="column">
                    {columnImages.map((img) => (
                        <div key={img.id} className="image-wrapper">
                            <img src={img.image} alt='img' />
                            <div className="overlay_img">
                                <div className="text">{img.name}<br />{img.price}/-</div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

function MainContent() {
    return (
        <main className="main-content">
            <Parallax pages={8} style={{ top: '0', left: '0' }}>
                <div className="overlay">
                    <ParallaxLayer offset={0} speed={0}>
                        <div className="App">
                            <Banner1 className="forward" images={images} speed={20000} />
                            <Banner2 className="backward" images={images} speed={20000} />
                            <Banner1 className="forward" images={images} speed={20000} />
                            <Banner2 className="forward" images={images} speed={20000} />
                        </div>
                    </ParallaxLayer>
                    <ParallaxLayer offset={1} speed={0}>
                        <img alt="bg2" className="bgimg2" src="assets/page2bg.jpeg"></img>
                    </ParallaxLayer>
                    <ParallaxLayer offset={2} speed={0}>
                        <img alt="bg3" className="bgimg3" src="assets/page2bg.jpeg"></img>
                    </ParallaxLayer>
                    <ParallaxLayer offset={0} speed={0}>
                        <div className="overlay-layer">
                            <div className="overlay-text">
                                <ParallaxLayer offset={0} speed={0.5}>
                                    <div className="overlay-text-white">Weave Possibilities with</div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={0} speed={1}>
                                    <div className="overlay-text-purple">Neural Thread AI</div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={0} speed={2}>
                                    <div className="dot"></div>
                                    <div className="overlay-text-small">Where AI Redefines Textile Design with <br />Seamless Patterns</div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={0} speed={4} horizontal>
                                    <div className="button_div">
                                        <button  className="button">Buy from Market</button>
                                    </div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={0} speed={-4} horizontal>
                                    <div className="button_div2">
                                        <button className="button">Create with AI</button>
                                    </div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={1} speed={2}>
                                    <div className="overlay-text-white-page2">Welcome to the Era of</div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={1} speed={3}>
                                    <div className="greytextbox"></div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={2} speed={2}>
                                    <div className="overlay-text-white-page3">because, Neural Thread <br />creates Patterns which are</div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={2} speed={3}>
                                    <div className="greytextbox2"></div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={3} speed={3}>
                                    <div className="overlay-text-white-page4">
                                        You connect every <br /> piece like a perfectly <br /> Designed Puzzle
                                    </div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={3} speed={2}>
                                    <img alt='img' src="assets/imgpage4.jpeg" className='imgpage4'></img>
                                </ParallaxLayer>
                                <ParallaxLayer offset={4} speed={2}>
                                    <div className="overlay-text-white-page5">
                                        What can you do with <br />Neural Thread
                                    </div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={4} speed={1}>
                                    <img alt='img' src="assets/imgpage4.jpeg" className='imgpage5-1'></img>
                                </ParallaxLayer>
                                <ParallaxLayer offset={4} speed={1}>
                                    <img alt="img" src="assets/imgpage4.jpeg" className='imgpage5-2'></img>
                                </ParallaxLayer>
                                <ParallaxLayer offset={5} speed={3}>
                                    <div className="overlay-text-white-page6-bold">
                                        PERSONALIZE
                                    </div>
                                    <div className="overlay-text-white-page6">
                                        your product from <br /> the custom patterns
                                    </div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={5} speed={4}>
                                    <div className="button_div2_page6">
                                        <button className="button">Create with AI</button>
                                    </div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={5} speed={4}>
                                    <div className="overlay-text-white-page6-small">
                                        join over 600+ creators
                                    </div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={5} speed={1}>
                                    <img alt='img' src="assets/page6img1.jpeg" className='page6img1'></img>
                                </ParallaxLayer>
                                <ParallaxLayer offset={5} speed={1.5}>
                                    <img alt="img" src="assets/page6img2.jpeg" className='page6img2'></img>
                                </ParallaxLayer>
                                <ParallaxLayer offset={5} speed={2}>
                                    <img alt='img' src="assets/page6img3.jpeg" className='page6img3'></img>
                                </ParallaxLayer>
                                <ParallaxLayer offset={5} speed={2.5}>
                                    <img alt="img" src="assets/page6img4.jpeg" className='page6img4'></img>
                                </ParallaxLayer>
                                <ParallaxLayer offset={6} speed={3}>
                                    <div className="overlay-text-white-page7-bold">
                                        EARN
                                    </div>
                                    <div className="overlay-text-white-page7">
                                        by selling your custom <br /> patterns in market
                                    </div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={6} speed={4}>
                                    <div className="button_div2_page7">
                                        <button className="button">Buy from Market</button>
                                    </div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={6} speed={4}>
                                    <div className="overlay-text-white-page7-small">
                                        join over 600+ creators
                                    </div>
                                </ParallaxLayer>
                                <ParallaxLayer offset={6} speed={1}>
                                    <img alt='img' src="assets/page6img1.jpeg" className='page7img1'></img>
                                </ParallaxLayer>
                                <ParallaxLayer offset={6} speed={1.5}>
                                    <img alt="img" src="assets/page6img2.jpeg" className='page7img2'></img>
                                </ParallaxLayer>
                                <ParallaxLayer offset={6} speed={2}>
                                    <img alt='img' src="assets/page6img3.jpeg" className='page7img3'></img>
                                </ParallaxLayer>
                                <ParallaxLayer offset={6} speed={2.5}>
                                    <img alt="img" src="assets/page6img4.jpeg" className='page7img4'></img>
                                </ParallaxLayer>
                                <ParallaxLayer offset={7} speed={0}>
                                    <div className="overlay-text-white-page8">
                                        Some of the best Collections
                                    </div>
                                    <ImageGrid images={images} />
                                </ParallaxLayer>
                            </div>
                        </div>
                    </ParallaxLayer>
                </div>
            </Parallax>
        </main>
    );
}*/

// App Component
function App() {
    return (
        <div className="app">
            <LandingPage/>
            {/* <Header /> */}
            {/* <MainContent /> */}
            {/* <Footer /> */}
        </div>
    );
}

export default App;
