const Banner1 = ({ images, speed = 5000 }) => {
    return (
      <div className="inner">
        <div className="wrapper">
        <section style={{ 
  "--speed": `${speed}ms`, 
  display: "flex",
  animation: `swipeforward var(--speed) linear infinite`
}}>

            {images.map(({ id, image }) => (
              <div className="image" key={id}>
                <img src={image} alt={id} />
              </div>
            ))}
          </section>
          <section style={{ 
  "--speed": `${speed}ms`, 
  display: "flex",
  animation: `swipeforward var(--speed) linear infinite`
}}>

            {images.map(({ id, image }) => (
              <div className="image" key={id}>
                <img src={image} alt={id} />
              </div>
            ))}
          </section>
          <section style={{ 
  "--speed": `${speed}ms`, 
  display: "flex",
  animation: `swipeforward var(--speed) linear infinite`
}}>
            {images.map(({ id, image }) => (
              <div className="image" key={id}>
                <img src={image} alt={id} />
              </div>
            ))}
          </section>
        </div>
      </div>
      
    );
  };
  
  export { Banner1 };
  